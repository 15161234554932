<template>
  <div class="d-flex flex-column align-items-center">
    <div class="font-weight-bold f-16">ยืนยันรหัสผ่านใช้ครั้งเดียว (OTP)</div>
    <div id="customer-contactNo" class="text-center title-otp mt-3">
      SKL ได้ส่งรหัสผ่าน OTP ไปยังหมายเลข {{ MobileNumber.slice(0, 3) }}-XXX-{{
        MobileNumber.slice(6)
      }}
    </div>
    <div class="title-otp">กรุณากรอกรหัสผ่าน</div>
    <div class="title-prefix f-12 mt-3 bg-green-secondary px-2 py-1">
      <p>( รหัสอ้างอิง {{ prefix }} )</p>
    </div>

    <InputOtp
      class="w-100 mt-2"
      :id="id"
      @input="val => (valueMutator = val)"
      :type="type"
    />
    <div
      class="w-100 text-center not-received-otp mt-2 d-flex justify-content-center"
    >
      ไม่ได้รับหมายเลข OTP?&nbsp;&nbsp;
      <div id="send-OTP-again" class="ml-4" @click="sendOTPAgain">
        <u class="ml-2">ส่งอีกครั้ง</u>
      </div>
    </div>
  </div>
</template>

<script>
const InputOtp = () => import("@/components/inputs/InputOtp");

export default {
  name: "form-opt",
  components: {
    InputOtp
  },
  props: {
    id: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: String,
      default: ""
    },
    placeholder: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String,
      default: "text"
    },
    pattern: {
      required: false,
      type: String
    },
    inputmode: {
      required: false,
      type: String,
      default: "text"
    },
    required: {
      required: false,
      type: Boolean
    },
    isError: {
      required: false,
      type: Boolean
    },
    MobileNumber: {
      required: true
    },
    prefix: {
      required: false,
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    valueMutator: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  },
  created() {},
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event);
    },
    sendOTPAgain() {
      this.$emit("sendOTPAgain");
    }
  }
};
</script>

<style scoped>
.title-otp {
  font-size: 14px;
}
.not-received-otp {
  font-size: 12px;
}
.input-text {
  width: auto !important;
}

.title-prefix {
  border-radius: 15px;
}
</style>
